@tailwind base;
@tailwind components;
@tailwind utilities;

.card-field {
  line-height: 1.5;
  margin-bottom: 1rem;
  border-radius: 0.25rem;
  padding: 0.55rem 0.75rem;
  background-color: white;
  border: 1px solid #ced4da;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

.card-field.StripeElement--focus {
  outline: 0;
  border-color: #80bdff;
  box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25);
}

.loading {
  animation: spin 1s infinite linear;
}

@keyframes spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.loading-reverse {
  animation: spin-reverse 1s infinite linear;
}

@keyframes spin-reverse {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(-360deg);
  }
}

.slider {
  -webkit-appearance: none;
  height: 20px;
  background: #d3d3d3;
  outline: none;
  opacity: 0.7;
  -webkit-transition: 0.2s;
  transition: opacity 0.2s;
}

.slider:hover {
  opacity: 1;
}

.slider::-webkit-slider-thumb {
  -webkit-appearance: none;
  appearance: none;
  width: 30px;
  height: 20px;
  border-radius: 0.5rem;
  background: rgb(55, 48, 163);
  cursor: pointer;
}

.slider::-moz-range-thumb {
  width: 30px;
  height: 20px;
  border-radius: 0.5rem;
  background: rgb(55, 48, 163);
  cursor: pointer;
}

.modal p,
.modal h2 {
  margin: 1rem 0;
}

.modal h2 {
  font-weight: 500;
}

.play {
  margin-left: -24px;
  margin-top: -14px;
}
